import { useState } from 'react';
import isNumber from 'lodash/isNumber';
import * as Yup from 'yup';

import { RISK_QUOTE_STATUS_QUOTED } from 'consts';
import { usePatchQuote } from 'lib/quoteBind';
import * as utils from 'utils';

// app
import { EditRiskQuoteView } from './EditRiskQuote.view';

export const EditRiskQuote = ({ quote, handleClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutateAsync: patchRiskQuote } = usePatchQuote();

  const quoteStatus = quote.response ? quote.response.responseStatus : quote.status;
  const isQuoted = quoteStatus === RISK_QUOTE_STATUS_QUOTED;

  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    const result = await patchRiskQuote({ formData: data, quote, accept: false });
    if (result) handleClose?.();
    else setIsSubmitting(false);
  };

  const handleSubmitAndAccept = async (data) => {
    setIsSubmitting(true);
    const result = await patchRiskQuote({ formData: data, quote, accept: true });
    if (result) handleClose?.();
    else setIsSubmitting(false);
  };

  // abort
  if (!quote || !quote.id) return null;

  const fields = [
    [
      {
        gridSize: { xs: 12, sm: 6 },
        type: 'number',
        name: 'grossPremium',
        value: isNumber(quote?.commission?.revisedPremium) ? quote.commission.revisedPremium : quote.grossPremium,
        label: `${utils.string.t('risks.grossPremium')}${quote?.currency ? ` (${quote?.currency.trim()})` : ``}`,
        validation: Yup.number()
          .nullable()
          .transform(function (value, originalvalue) {
            return this.isType(value) ? (Number.isNaN(value) ? null : value) : null;
          })
          .currency()
          .required(utils.string.t('validation.required')),
        disabled: isSubmitting,
      },
      {
        gridSize: { xs: 12, sm: 6 },
        type: 'datepicker',
        name: 'validUntil',
        label: utils.string.t('risks.quoteValidUntil.label'),
        value: quote.validUntil,
        outputFormat: 'iso',
        muiPickerProps: {
          minDate: utils.date.tomorrow(),
        },
        muiComponentProps: {
          fullWidth: true,
          disabled: isSubmitting,
        },
        validation: Yup.string().nullable().required(utils.string.t('risks.quoteValidUntil.required')),
      },
    ],
    {
      type: 'hidden',
      name: 'riskId',
      value: quote.riskId,
    },
  ];

  const updateAndAccept = {
    name: 'secondary',
    label: utils.string.t('risks.updateAndAccept'),
    handler: handleSubmitAndAccept,
    disabled: isSubmitting,
    tooltip: {
      title: utils.string.t('risks.updateLegend.submitAccept'),
    },
  };

  const actions = [
    {
      name: 'submit',
      label: utils.string.t('risks.update'),
      handler: handleSubmit,
      disabled: isSubmitting,
      tooltip: {
        title: utils.string.t('risks.updateLegend.submit'),
      },
    },
    {
      name: 'cancel',
      label: utils.string.t('app.cancel'),
      handler: handleClose,
      disabled: isSubmitting,
    },
  ];

  return <EditRiskQuoteView fields={fields} actions={isQuoted ? actions : [updateAndAccept, ...actions]} />;
};

export default EditRiskQuote;
